<template>
  <div :class="className" @click.stop="onClick">
    <span v-if="!locked && !noReload" class="w-8">
      <x-icon
        class="btn-info btn-circle btn-xs"
        name="rotate"
        @click.stop="reload"
      />
    </span>
    <div
      class="flex-start space-x-2 lg:w-full w-24 overflow-scroll"
      v-tooltip="tooltip"
    >
      <span>{{ account.name }}</span>
      <span class="text-error" @click.stop="unlock" v-if="locked">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-lock"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z"
          />
          <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
          <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
        </svg>
      </span>
      <span v-else-if="account.autoEntry" class="text-warning">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-clock-cog"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M21 12a9 9 0 1 0 -9.002 9" />
          <path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
          <path d="M19.001 15.5v1.5" />
          <path d="M19.001 21v1.5" />
          <path d="M22.032 17.25l-1.299 .75" />
          <path d="M17.27 20l-1.3 .75" />
          <path d="M15.97 17.25l1.3 .75" />
          <path d="M20.733 20l1.3 .75" />
          <path d="M12 7v5l2 2" />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import { credentialsActions } from '@/store/constants.js'
import XIcon from '@/components/daisy/atoms/icons/index.vue'
export default {
  name: 'account-name',

  components: {
    XIcon,
  },

  props: {
    noReload: Boolean,
    account: Object,
  },

  data: () => ({
    isMobile: global.IS_MOBILE,
  }),

  computed: {
    className() {
      return 'flex space-x-2 cursor-pointer'
    },
    locked() {
      if (new Date(this.account.lockUntil).getTime() > Date.now()) {
        return true
      }
      return false
    },
    tooltip() {
      if (this.locked) {
        return 'Locked'
      }
      if (this.account.autoEntry) {
        return 'Auto entry'
      }
      return ''
    },
  },

  methods: {
    async unlock() {
      this.$$confirm({
        title: 'Notice',
        text: 'Do you want to unlock this account?',
        confirmText: 'Yes',
        confirmColor: 'warning',
        onConfirm: async () => {
          //'common/INCREASE_COUNTER'
          this.$store.commit('common/INCREASE_COUNTER')
          await this.$http.post('/api/v2/account/unlock', {
            id: this.account._id,
          })
          await this.$store.dispatch(
            `credentials/${credentialsActions.LOAD_CREDENTIALS}`,
            { force: true }
          )
          this.$store.commit('common/DECREASE_COUNTER')
        },
      })
    },
    async reload() {
      this.$store.commit('common/INCREASE_COUNTER')
      await this.$http.post('/api/v2/account/sync', {
        credentialId: this.account._id,
      })
      let start = Date.now()
      let counter = 0
      const timer = setInterval(async () => {
        counter++
        const lastUpdated = await this.$store.dispatch(
          `credentials/${credentialsActions.LOAD_CREDENTIALS}`,
          {
            force: true,
          }
        )
        if (lastUpdated > start || counter > 10) {
          clearInterval(timer)
          this.$store.commit('common/DECREASE_COUNTER')
        }
      }, 3e3)
    },
    onClick() {
      localStorage.___accountId = this.account._id
      this.$router.push('/logic-orders')
    },
  },
}
</script>
